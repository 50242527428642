
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"

export default defineComponent({
  components: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("发货列表", ["发货管理;shipping_orders"])
    })

    const store = useStore()
    const router = useRouter()
    const tableData = ref([])

    const formData = ref({
      no: '',
      user_nickname: '',
      user_mobile: '',
      name: '',
      mobile: '',
      status: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_SHIPPING_ORDERS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentShippingOrders;
          page.value.totalResult = store.getters.currentShippingOrderCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        no: '',
        user_nickname: '',
        user_mobile: '',
        name: '',
        mobile: '',
        status: ''
      }
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset
    }
  },
})
